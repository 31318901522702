import "./scss/codingHeader.scss";
import "../../scss-utils/buttons.scss";
import { useFormikContext } from "formik";
import { Link, useNavigate } from "react-router-dom";
import authHeader from "../../service/auth-header";
import { toast } from "react-toastify";

function CodingHeader({
                        text,
                        evaluating,
                        slug,
                        entryId,
                        endQuestion,
                        evaluationId
                      }) {
  const { submitForm } = useFormikContext();
  const navigate = useNavigate();

  const unCodableExercise = (entryId) => {
    if (window.confirm(`Opravdu se cvičení nedá okódovat a odebrat ho z kódování?`)) {
      const headers = authHeader();
      fetch(`/api/evaluationExerciseUnCodable/${ entryId }`, {
        method: "PUT",
        headers,
      }).then(response => response.json()).then(response => {
        toast.info(response.message);
        navigate(`/kodovani`)
      })


    }
  }

  return (
    <div className="coding-header">
      <div className={ "flex-1 coding-header-text" }>
        <div className={ "exercise-name" }> { text }</div>
        { !evaluating && (
          <Link to="/kodovani">
            <button>Výběr</button>
          </Link>
        ) }
      </div>

      { entryId && (
        <div className={ "flex-1" }>
          <a
            href={ `https://www.historylab.cz/cviceni/prohlizeni/${ slug }?entryId=${ entryId }` }
            target="_blank"
            className={"link"}
          >
            { entryId }
          </a>
        </div>
      ) }
      <div className={ "flex-1 header-buttons" }>
        { endQuestion && endQuestion.value !== "" && endQuestion.value !== null && (
          <button
            className={ "button warning" }
            onClick={ () => {
              alert(endQuestion.value);
            } }
          >
            Žákova poznámka
          </button>
        ) }
        <div>
          <button
            className={ "button" }
            onClick={ () => {
              unCodableExercise(entryId)
            } }
          >
            Nevyplnitelné
          </button>

        </div>

        { evaluating && (
          <button
            className={ "button save" }
            onClick={ async () => {
              await submitForm();
            } }
          >
            Uložit cvičení
          </button>
        ) }
      </div>
    </div>
  );
}

export default CodingHeader;