import authHeader from "../../service/auth-header";
import axios from "axios";

export const loadExportOverview = async (slug) => {
  const headers = authHeader();
  headers["Content-Type"] = "application/json";
  let response = await fetch(
    `/api/export/overview/${ slug }`,
    {
      method: "GET",
      headers,
    }
  );
  return await response.json();
};

export const loadExportCSVMetadata = async (slug) => {
  const headers = authHeader();
  headers["Content-Type"] = "application/json";
  let response = await fetch(
    `/api/export/metadata/${ slug }`,
    {
      method: "GET",
      headers,
    }
  );
  return await response.json();
};

export const getCSVEvaluationData = async (slug, templateId, params) => {
  const headers = authHeader();
  axios({
    method: "get",
    url: `/api/export/getCSVData/${ slug }/${ templateId }`,
    responseType: "blob",
    headers
  })
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      const fromData = new Date(params.from);
      const toData = new Date(params.to);
      const fromDataFormat = `${ fromData.getFullYear() }-${ fromData.getMonth() + 1 }-${ fromData.getDate() }`
      const toDataFormat = `${ toData.getFullYear() }-${ toData.getMonth() + 1 }-${ toData.getDate() }`
      link.setAttribute("download", `${ slug }-export ${ fromDataFormat } _ ${ toDataFormat }.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((error) => {
      alert(error);
    });
};
