import authHeader from "../../service/auth-header";

export const loadExercise = async (slug, restPeriod = false) => {
  const data = { slug: slug, restPeriod };
  const headers = authHeader();
  headers["Content-Type"] = "application/json";
  let response = await fetch(`/api/evaluation/getNotEvaluatedExercise`, {
    method: "POST",
    headers,
    body: JSON.stringify(data),
  });
  response = await response.json();
  if (response.found) {
    return { found: true, response };
  } else return { found: false, response: response };
};

export const loadExerciseById = async (slug, entryId, evaluationId) => {
  const headers = authHeader();
  headers["Content-Type"] = "application/json";
  let response = await fetch(`/api/evaluation/loadSpecificEvaluation/${slug}/${entryId}/${evaluationId}`, {
    method: "GET",
    headers,
  });
  response = await response.json();
  if (response.found) {
    return { found: true, evaluation: response.evaluation };
  } else return { found: false, message: response.message };
};

export const saveExercise = async (exerciseData, evaluationData) => {
  const data = {
    evaluationId: exerciseData._id,
    evaluationData,
  };
  const headers = authHeader();
  headers["Content-Type"] = "application/json";
  let response = await fetch(`/api/evaluation/saveEvaluatedExercise`, {
    method: "POST",
    headers,
    body: JSON.stringify(data),
  });
  response = await response.json();
  if (response.found) {
    return { success: true, message: response.message };
  } else return { success: false, message: response.message };
};

export const loadEvaluationQuestions = async (slug) => {
  const headers = authHeader();
  headers["Content-Type"] = "application/json";
  let response = await fetch(
    `/api/evaluation/loadEvaluationQuestions/${slug}`,
    {
      method: "GET",
      headers,
    }
  );
  response = await response.json();
  if (response.found) {
    return { found: true, response };
  } else return { found: false, response: response };
};