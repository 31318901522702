import "./headerRow.scss";
import authHeader from "../../service/auth-header";
import { toast } from "react-toastify";

function CoderRow({ evaluationQuestions, evaluation, fetchData }) {
  if (!evaluationQuestions) return <></>;
  const slides = evaluationQuestions.evaluationQuestions;
  const evaluationData = evaluation.evaluationData;

  const convertDate = (ISOdate) => {
    if (ISOdate === null) return 'Nevyplněno';
    const date = new Date(ISOdate);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hour = date.getHours();
    const minute = date.getMinutes();
    return `${ day }-${ month }-${ year } | ${ hour }:${ minute }`;
  };

  const activationOfEvaluation = (evaluationId, enableEvaluation, coder) => {

    const headers = authHeader();
    headers["Content-Type"] = "application/json";
    fetch(`/api/evaluation/activate/${ evaluationId }`, {
      method: "POST",
      headers,
      body: JSON.stringify({ enable: !enableEvaluation, coder })
    }).then(response => response.json()).then(response => {
      toast.info(response)
      console.log(response);
      fetchData();
    })
  }

  const deleteDataOfEvaluation = (evaluationId) => {
    if (window.confirm(`Opravdu chcete odstranit data kodéra?`)) {
      const headers = authHeader();
      fetch(`/api/evaluation/deleteData/${ evaluationId }`, {
        method: "POST",
        headers,
      }).then(response => response.json()).then(response => {
        toast.info(response);
        console.log(response);
        fetchData();
      })
    }
  }

  const deleteEvaluation = (evaluationId) => {
    if (window.confirm(`Opravdu chcete odstranit evaluaci kodéra?`)) {
      const headers = authHeader();
      fetch(`/api/evaluationDelete/${ evaluationId }`, {
        method: "DELETE",
        headers,
      }).then(response => response.json()).then(response => {
        toast.info(response)
        console.log(response);
        fetchData();
      })
    }
  }

  const renderAdditionalInfo = (evaluation) => {
    return (
      <div className={ "additional-info row gap-mini" }>
        <button className={ "button mini" }
                onClick={ () => activationOfEvaluation(evaluation.entryId, evaluation.enable, evaluation.coder) }>
          { evaluation.enable ? "Deaktivovat" : "Aktivovat" }
        </button>
        <button className={ "button mini" }
                onClick={ () => deleteDataOfEvaluation(evaluation._id) }>
          SmazatData
        </button>
        <button className={ "button danger mini" }
                onClick={ () => deleteEvaluation(evaluation._id) }>
          Smazat Evaluaci
        </button>
      </div>
    )
  }

  let rowCss = ""
  if(evaluation.submissionNumber > 0)rowCss+= "second-evaluation ";
  else{
    if( evaluation.evaluationBegun && !evaluation.isEvaluated) rowCss+= "started ";
    if( evaluation.isEvaluated) rowCss+= "evaluated ";
  }


  return (
    <div className={ `evaluation-header-row ${rowCss}` }>
      <div>
        <div className={ `slide-coder ${evaluation.enable? "":"evaluation-disable"}` }>{ evaluation.coderName }</div>
      </div>
      <div>
        <div
          className={ `slide-time ${evaluation.enable? "":"evaluation-disable"}` }>{ convertDate(evaluation.startTime ? evaluation.startTime : null) }</div>
      </div>
      <div>
        <div
          className={ `slide-time ${evaluation.enable? "":"evaluation-disable"}` }>{ convertDate(evaluation.endTime ? evaluation.endTime : null) }</div>
      </div>
      { slides &&
        slides.map((slide, indexSlide) => {
          return (
            <div key={ indexSlide } className={ `slide-data ${evaluation.enable? "":"evaluation-disable"}` }>
              { slide.questions.map((questions, indexQuestion) => {
                  if (questions.input === "select")
                    return questions.set.map((select, indexQuestionSelect) => {
                      let data = null;
                      if (questions.type === "checkbox") {
                        data = evaluationData ? evaluationData[select.id] : '';
                      } else if (questions.type === "radio") {
                        data = evaluationData ? evaluationData[questions.id] === select.id : '';
                      }
                      if (!data)
                        return (
                          <span
                            key={ indexQuestionSelect }
                            className={ "slide-select  coder-row" }
                          >
                          <div className={ "slide-question" }/>
                        </span>
                        );
                      return (
                        <span
                          key={ indexQuestionSelect }
                          className={ "slide-select" }
                        >
                        <div
                          className={ `slide-question ${ data ? "checked" : "" }` }
                        >
                          &#10003;
                        </div>
                      </span>
                      );
                    });
                  const data = evaluationData ? evaluationData[questions.id] : '';
                  if (!data)
                    return (
                      <div key={ indexQuestion } className={ "slide-question" }/>
                    );
                  return (
                    <div
                      key={ indexQuestion }
                      className={ `slide-question ${ data ? "checked" : "" }` }
                    >
                      { data === true ? String.fromCharCode(10003) : JSON.stringify(data) }
                    </div>
                  );
                }
              ) }
            </div>
          );
        }) }
      { renderAdditionalInfo(evaluation) }
    </div>
  );
}

export default CoderRow;