import { Buffer } from "buffer";

import "./scss/styles.scss";
import "./scss/svgHl.scss";

function SvgHl({ data }) {
  function createMarkup(data) {
    let base64ToString = Buffer.from(data, "base64").toString();
    base64ToString = base64ToString.replaceAll(
      "<textarea",
      "<textarea readonly"
    );
    // let clean = DOMPurify.sanitize(base64ToString);
    return { __html: `${base64ToString}` };
  }

  return (
    <div>
      {data &&
        data.map((svg, index) => {
          return (
            <div
              className={"svg-hl"}
              key={`svg-pic-${index}`}
              dangerouslySetInnerHTML={createMarkup(svg.answer)}
            />
          );
        })}
    </div>
  );
}

export default SvgHl;