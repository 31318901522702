import { getCSVEvaluationData, loadEvaluationData } from "./AccountService";
import { useEffect, useState } from "react";
import withAuthentication from "../../Auth/withAuthentication";
import { loadEvaluationQuestions } from "../coding/CodingService";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./evaluationPeriodDetailPage.scss";
import { Field, FormikProvider, useField, useFormik } from "formik";
import authHeader from "../../service/auth-header";
import EvaluationRow from "./EvaluationRow";
import { toast } from "react-toastify";

function EvaluationPeriodDetailPage() {
  let { slug, id } = useParams();
  const user = JSON.parse(localStorage.getItem("user"));
  const name = user.username;
  const roles = user.roles;
  const [periodData, setPeriodData] = useState([]);
  const [periodMetadata, setPeriodMetadata] = useState({});
  const [evaluationQuestions, setEvaluationQuestions] = useState([]);
  const [addEntryId, setAddEntryId] = useState("");
  const [entryIdWithProblem, setEntryIdWithProblem] = useState(null);
  const navigate = useNavigate();

  async function fetchData() {
    const headers = authHeader();
    fetch(`/api/evaluationPeriod/${ id }`, {
      method: "GET",
      headers,
    }).then(response => response.json()).then(response => {
      setPeriodData(response.periodData)
    });
    fetch(`/api/periodMetadata/${ id }`, {
      method: "GET",
      headers,
    }).then(response => response.json()).then(response => {
      setPeriodMetadata(response.periodMetadata)
    });
    loadEvaluationQuestions(slug).then(r => {
      setEvaluationQuestions(r.response);
    });
  }

  useEffect(() => {
    fetchData();
  }, []);

  const addEntryIdRequest = async (exerciseIDs) => {
    const values = { exerciseIDs, id };
    const headers = authHeader();
    headers["Content-Type"] = "application/json";
    let response = await fetch(`/api/evaluationPeriod`, {
      method: "PUT",
      headers,
      body: JSON.stringify(values),
    });
    response = await response.json();
    navigate(`/administrace/obdobi/${ slug }/${ id }`);
    setEntryIdWithProblem(response.notAddedEntryId)
    fetchData();
    setAddEntryId("");
    toast.info(response.message);
  }

  const activatePeriod = async (periodMetadata) => {
    const values = {
      periodId: periodMetadata._id,
      enable: !periodMetadata.enable
    };
    const headers = authHeader();
    headers["Content-Type"] = "application/json";
    let response = await fetch(`/api/periodMetadataEnable`, {
      method: "PUT",
      headers,
      body: JSON.stringify(values),
    });
    response = await response.json();
    navigate(`/administrace/obdobi/${ slug }/${ id }`);
    toast.info(response.message);
    fetchData();
  }

  const changePeriodCoverage = async (periodMetadata) => {
    if (window.confirm(`Opravdu chcete změnit periodu? Tato možnost může aktivovat, deaktivovat všehcna cvičení`)) {

      const coverageChange = periodMetadata.coverage === "all" ? "one": "all";

      const values = {
        periodId: periodMetadata._id,
        coverage: coverageChange
      };
      const headers = authHeader();
      headers["Content-Type"] = "application/json";
      let response = await fetch(`/api/changePeriodCoverage`, {
        method: "PUT",
        headers,
        body: JSON.stringify(values),
      });
      response = await response.json();
      navigate(`/administrace/obdobi/${ slug }/${ id }`);
      toast.info(response.message);
      fetchData();
    }
  }

  const deletePeriod = async (periodMetadata) => {
    if (window.confirm(`Opravdu chcete odstranit periodu? Sma6ete i všechny evaluace k ní přiřazené`)) {
      const headers = authHeader();
      headers["Content-Type"] = "application/json";
      let response = await fetch(`/api/period/${ periodMetadata._id }`, {
        method: "DELETE",
        headers,
      });
      response = await response.json();
      toast.info(response.message);
      navigate(`/administrace`);
    }

  }

  const exportPeriod = async (periodMetadata) => {
    const headers = authHeader();
    headers["Content-Type"] = "application/json";
    let response = await fetch(`/api/periodExport/${ periodMetadata._id }`, {
      method: "GET",
      headers,
    });
    const blob = await response.blob();
    // download(blob, "test.pdf");

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${ slug }-periodId-${ id }-export.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    toast.info(response.message);
  }

  const addNotDoneEvaluationToRest = async (periodMetadata) => {
    if (window.confirm(`Opravdu chcete nevyplněné evaluace přesunout do REST periody?`)) {
      const headers = authHeader();
      headers["Content-Type"] = "application/json";
      let response = await fetch(`/api/periodAddNotDoneEvaluationToRest/${ periodMetadata._id }/${ periodMetadata.slug }`, {
        method: "PUT",
        headers,
      });
      response = await response.json();
      toast.info(response.message);
      fetchData();
    }
  }

  return (
    <div className={ "evaluation-period-page-detail" }>
      <div className="coding-header">
        <button
          className={ "button" }
          onClick={ () => {
            localStorage.removeItem("user");
            window.location.reload();
          } }
        >
          Odhlásit se{ " " }
        </button>
        <div style={ { display: "flex" } }>
          { slug } - perioda: { periodMetadata.name }
        </div>
        <div className={ "row" }>
          <Link className={ "no-link" } to="/administrace">
            <button className={ "button" }>Administrace</button>
          </Link>
          <Link className={ "no-link" } to="/">
            <button className={ "button" }>Hlavní stránka</button>
          </Link>
        </div>
      </div>

      <div className={ "admin-period eval-detail-page" }>
        <div className={ "row" }>
          <button className={ "button" }
                  onClick={ () => exportPeriod(periodMetadata) }>
            EXPORT
          </button>
          <div><input onChange={ (e) => setAddEntryId(e.target.value) }/></div>
          <div>
            <button className={ "button" }
                    onClick={ () => addEntryIdRequest(addEntryId) }>Přidat
              cvičení
            </button>
          </div>
          <div className={ "row" }>
            <div>Perioda status:</div>
            <div
              className={ `information-text ${ periodMetadata.enable ? "success" : "" }` }> { periodMetadata.enable ? "AKTIVNÍ" : "neaktivní" }</div>
            <button className={ "button" }
                    onClick={ () => changePeriodCoverage(periodMetadata) }>
              { periodMetadata.coverage }
            </button>

          </div>
          <button className={ "button" }
                  onClick={ () => activatePeriod(periodMetadata) }>
            { periodMetadata.enable ? "Deaktivovat" : "Aktivovat" }
          </button>
          <button className={ "button danger" }
                  onClick={ () => deletePeriod(periodMetadata) }>
            Smazat periodu?
          </button>
          { entryIdWithProblem && <div>EntryId, které nebyly
            přidány: { JSON.stringify(entryIdWithProblem) }</div> }
          { !periodMetadata.rest &&
            <button className={ "button warning" }
                    onClick={ () => addNotDoneEvaluationToRest(periodMetadata) }>
              Přesunout periodu do REST?
            </button>
          }
        </div>
        { periodData.map((evaluation, index) =>
          <EvaluationRow
            key={ index }
            evaluationData={ evaluation }
            slug={ slug } evaluationQuestions={ evaluationQuestions }
            evaluationPeriodId={ id }
            fetchData={ fetchData }

          />) }
      </div>

    </div>
  );
}

export default withAuthentication(EvaluationPeriodDetailPage);