function TextQuestion({ data, values, handleChange }) {
  return (
    <div>
      <label htmlFor={data.id}>{data.name}</label>
      <input
        type="text"
        id={data.id}
        name={data.id}
        onChange={handleChange}
        // onBlur={handleBlur}
        value={values[data.id] ? values[data.id] : ""}
      />
    </div>
  );
}

export default TextQuestion;
