import "../../App.css";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ExerciseRow from "./ExerciseRow";
import CodingHeader from "./CodingHeader";
import "./scss/codingPage.scss";
import withAuthentication from "../../Auth/withAuthentication";
import { ApplicationStateConsumer } from "../../Auth/ApplicationStateProvider";
import {
  loadEvaluationQuestions,
  loadExerciseById,
  saveExercise,
} from "./CodingService";
import { Formik } from "formik";
import { toast } from "react-toastify";

function CodingPage() {
  const [evaluationData, setEvaluationData] = useState(null);
  const [evaluating, setEvaluating] = useState(false);
  // const [errorMessage, setErrorMessage] = useState(null);
  const [headerText, setHeaderText] = useState("Vyberte cvičení");
  const [evaluationQuestions, setEvaluationQuestions] = useState(null);
  const navigate = useNavigate();

  let { slug, entryId, evaluationId } = useParams();

  useEffect(() => {
    switch (slug) {
      case "proc-je-v-ceskoslovenske-skole":
        setHeaderText("Proč je v československé škole?");
        break;
      case "co-kluci-provedli":
        setHeaderText("Co kluci provedli?");
        break;
      default:
        setHeaderText(slug);
    }

    (async () => {
      const result = await loadEvaluationQuestions(slug);
      if (result.found) {
        setEvaluationQuestions(result.response.evaluationQuestions);
      } else {
        toast.error(result.message)
      };
    })();

    async function fetchData() {
      const response = await loadExerciseById(slug, entryId, evaluationId);
      if(response.found){
        setEvaluationData(response.evaluation);
        setEvaluating(true);
      } else{
        toast.warning(response.message)
        navigate(`/kodovani`)
      }
    }
    fetchData();
  }, [slug, entryId, evaluationId, navigate]);

  const validate = (values) => {
    let isValid = "";
    if(values && Object.keys(values).length === 0 && Object.getPrototypeOf(values) === Object.prototype){
      isValid+="Nic jste nevyplnili.\n";
    }
    return { isValid: isValid === "", message: isValid }
  }
  if(!evaluationData) return <></>

  return (
    <ApplicationStateConsumer>
      {({ authenticated, login }) => (
        <div className="coding-page">
          <Formik
            initialValues={evaluationData.evaluationData || {}}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              // HARDCODED remove last dependency values
              if(values["6-1"] !== undefined){
                if(values["6-1"] === false){
                  delete values["6-1"];
                  delete values["6-2"];
                }
              }

              const validation = validate(values);
              if(!validation.isValid){
                toast.warning(validation.message)
                return;
              }
                const result = await saveExercise(evaluationData, values);
                if (result.success) {
                  setEvaluationData(null);
                  setEvaluating(false);
                  resetForm({ values: {} });
                  toast.info(result.message)
                  navigate(`/kodovani`)
                } else{
                  toast.error(result.message)
                }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <>
                <CodingHeader
                  text={headerText}
                  evaluating={evaluating}
                  slug={slug}
                  entryId={evaluationData && evaluationData.entryId}
                  evaluationId={evaluationId}
                  endQuestion={
                    evaluationData && evaluationData.exercise.endQuestion
                  }
                />
                {/*{errorMessage && <div>{errorMessage}</div>}*/}
                {evaluationData && evaluationQuestions && (
                  <div className={"coding-main"}>
                    <form className={"coding-form"} onSubmit={handleSubmit}>
                      <ExerciseRow
                        metadata={{}}
                        slides={evaluationData.exercise.questions}
                        evaluationQuestions={evaluationQuestions}
                        evaluationValues={values}
                        onChangeInput={handleChange}
                        setFieldValue={setFieldValue}
                      />
                    </form>
                  </div>
                )}
              </>
            )}
          </Formik>
        </div>
      )}
    </ApplicationStateConsumer>
  );
}

export default withAuthentication(CodingPage);