import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import CodingPage from "./components/coding/CodingPage";
import CPSelectExercise from "./components/comparisonPage/CPSelectExercise";
import ComparisonPage from "./components/comparisonPage/ComparisonPage";
import RatingPage from "./components/rating/RatingPage";
import RatingResultSelectPage from "./components/rating/RatingResultSelectPage";
import RatingEvaluationSelectPage from "./components/rating/RatingEvaluationSelectPage";
import RatingEvaluationPage from "./components/rating/RatingEvaluationPage";
import RatingResultExercisePage from "./components/rating/RatingResultExercisePage";
import RatingResultAllSelectPage from "./components/rating/ResultAll/RatingResultAllSelectPage";
import RatingResultAllExercisePage from "./components/rating/ResultAll/RatingResultAllExercisePage";
import LoginPage from "./components/login/LoginPage";
import CodingSelect from "./components/coding/CodingSelect";
import { ApplicationStateProvider } from "./Auth/ApplicationStateProvider";
import { NotificationProvider } from "./Auth/NotificationProvider";
import history from "./history";
import AccountPage from "./components/account/AccountPage";
import ExportPage from "./components/export/ExportPage";
import NewEvaluationPeriodPage
  from "./components/account/NewEvaluationPeriodPage";
import EvaluationPeriodDetailPage
  from "./components/account/EvaluationPeriodDetailPage";
import { ToastContainer } from "react-toastify";

ReactDOM.render(
  <BrowserRouter history={history}>
    <ToastContainer />
    <NotificationProvider>
      <ApplicationStateProvider>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/administrace" element={<AccountPage />} />
          <Route path="/administrace/nove-obdobi/:slug" element={<NewEvaluationPeriodPage />} />
          <Route path="/administrace/obdobi/:slug/:id" element={<EvaluationPeriodDetailPage />} />
          <Route path="srovnani" element={<CPSelectExercise />} />
          <Route path="srovnani/:slug" element={<ComparisonPage />} />
          <Route path="kodovani" exact element={<CodingSelect />} />
          <Route path="kodovani/:slug/:entryId/:evaluationId" element={<CodingPage />} />
          <Route path="rating" element={<RatingPage />} />
          <Route path="rating/result" element={<RatingResultSelectPage />} />
          <Route
            path="rating/evaluation/:userId"
            element={<RatingEvaluationSelectPage />}
          />
          <Route
            path="rating/evaluation/:userId/:slug/:entryId"
            element={<RatingEvaluationPage />}
          />
          <Route
            path="rating/result/:slug/:entryId"
            element={<RatingResultExercisePage />}
          />
          <Route
            path="rating/result-all/:slug/:entryId"
            element={<RatingResultAllExercisePage />}
          />
          <Route
            path="rating/result-all/:date"
            element={<RatingResultAllSelectPage />}
          />
          <Route path="export" element={<ExportPage />} />
        </Routes>
      </ApplicationStateProvider>
    </NotificationProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();