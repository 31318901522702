import React from "react";
import { ApplicationStateConsumer } from "./ApplicationStateProvider";
import LoginPage from "../components/login/LoginPage";
// import Login from "./Login";

function withAuthentication(Component) {
  // ...and returns another component...
  return function Authenticated(props) {
    // ... and renders the wrapped component with the context theme!
    // Notice that we pass through any additional props as well
    return (
      <ApplicationStateConsumer>
        {({
          authenticated,
          user,
          login,
          authenticationError,
          onAuthSuccess,
          onAuthFailure,
        }) => (
          <>
            {!authenticated && (
              <LoginPage
                login={login}
                error={authenticationError}
                onAuthSuccess={onAuthSuccess}
                onAuthFailure={onAuthFailure}
              />
            )}
            {authenticated && (
              <Component {...props} authenticated={authenticated} user={user} />
            )}
          </>
        )}
      </ApplicationStateConsumer>
    );
  };
}

export default withAuthentication;
