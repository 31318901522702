import { getCSVEvaluationData, loadEvaluationData } from "./AccountService";
import { useEffect, useState } from "react";
import withAuthentication from "../../Auth/withAuthentication";
import { loadEvaluationQuestions } from "../coding/CodingService";
import { Link, useNavigate } from "react-router-dom";
import "./accountPage.scss";
import authHeader from "../../service/auth-header";
import { toast } from "react-toastify";

function AccountPage() {
  const user = JSON.parse(localStorage.getItem("user"));
  const name = user.username;
  const roles = user.roles;
  const navigate = useNavigate();

  const [resultData, setResultData] = useState([]);
  const [selectedSlug, setSelectedSlug] = useState(null);
  const [selectedPage, setSelectedPage] = useState(null);

  const isAdmin = roles.some((role) => role === "ROLE_ADMIN");
  const [periods, setPeriods] = useState([]);

   const fetchData= async () => {
    const headers = authHeader();
    fetch(`/api/evaluationPeriod`, {
      method: "GET",
      headers,
    }).then(response => response.json()).then(response => setPeriods(response.periods))
  }

  useEffect(() => {
    fetchData();
  }, []);

  if (!isAdmin) {
    navigate(`/`);
    toast.warning("Nejste Admin!");
    return;
  }

  const newRestPeriod = async (slug) => {
    if (window.confirm(`Opravdu chcete založit novou REST periodu? Nevyplněné evaluace v předešlé se do nové přesypou.`)) {
      const headers = authHeader();
      let response = await fetch(`/api/evaluationPeriodRest/${ slug }`, {
        method: "POST",
        headers,
      });
      response = await response.json();
      toast.info(response.message);
      fetchData();
    }
  }

  const redirectPeriod = (slug, id) => {
    navigate(`/administrace/obdobi/${ slug }/${ id }`)
  }

  const renderPeriods = (slug) => {
    return periods.map(period => {
      if (period.slug !== slug) return;
      return <div key={ period.id }>
        <button className={ `card-button ${ period.enable ? "" : "disabled" } ${ period.rest ? "warning" : "" }` }
                style={ { cursor: "pointer" } }
                onClick={ () => redirectPeriod(slug, period._id) }>
          <div className={ "card-name" }>{ period.name }</div>
          <img
            className={ "card-img" }
            src={ `${ process.env.REACT_APP_ASSET_URL }cviceni-aktualni/assets/img/${ slug }/pic-00-1280w-thumb.jpg` }
            alt="IMAGE"
          />
        </button>
      </div>


    })
  }

  return (
    <div className={ "account-page" }>
      <div className="coding-header">
        <button
          className={ "button" }
          onClick={ () => {
            localStorage.removeItem("user");
            window.location.reload();
          } }
        >
          Odhlásit se{ " " }
        </button>
        <div style={ { display: "flex" } }>
          { "  " }Administrace
          - { name }
        </div>
        <Link className={ "no-link" } to="/">
          <button className={ "button" }>Hlavní stránka</button>
        </Link>
      </div>

      <div className={ "admin-period" }>
        <div className={ "admin-period-side proc-je-v-ceskoslovenske-skole" }>
          <div className={ "row" }>
            <Link className={ "no-link" }
                  to="/administrace/nove-obdobi/proc-je-v-ceskoslovenske-skole">
              <button className={ "button" }>Nové období pro <br/>
                proc-je-v-ceskoslovenske-skole
              </button>
            </Link>
            <button className={ "button warning" } onClick={()=>newRestPeriod("proc-je-v-ceskoslovenske-skole")}>Nová perioda pro nedodělané
              evaluace<br/>
              proc-je-v-ceskoslovenske-skole
            </button>
          </div>
          <div className={ "admin-section" }>
            { renderPeriods("proc-je-v-ceskoslovenske-skole") }
          </div>
        </div>
        <div className={ "admin-period-side co-kluci-provedli" }>
          <div className={ "row" }>
            <Link className={ "no-link" }
                  to="/administrace/nove-obdobi/co-kluci-provedli">
              <button className={ "button" }>Nové období pro <br/>
                co-kluci-provedli
              </button>
            </Link>
            <button className={ "button warning" } onClick={()=>newRestPeriod("co-kluci-provedli")}>Nová perioda pro nedodělané
              evaluace<br/>
              co kluci provedli
            </button>
          </div>
          <div className={ "admin-section" }>
            { renderPeriods("co-kluci-provedli") }
          </div>
        </div>
      </div>


    </div>
  );
}

export default withAuthentication(AccountPage);