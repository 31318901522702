import { FieldArray } from "formik";
import "./scss/questionsInputs.scss";

function SelectRadioQuestion({
  data,
  values,
  handleChange,
  setFieldValue,
  numberOfWords,
}) {
  return (
    <FieldArray
      name={`${data.id}`}
      render={(arrayHelpers) => (
        <>
          {data.countWord !== undefined && (
            <div className={"sq-name"}>Počet slov: {numberOfWords}</div>
          )}
            <div className="wrapper-buttons">
            {data.set &&
              data.set.map((selectElement, index) => (
                <>
                <input
                  className={ "sq-input" }
                  type="radio"
                  id={selectElement.id}
                  name={data.id}
                  onChange={() => setFieldValue(data.id, selectElement.id)}
                  defaultChecked={values[data.id] === selectElement.id}
                  value={values[data.id] === selectElement.id}
                />
                  <label htmlFor={selectElement.id} className={ `option ${values[data.id] === selectElement.id? "checked":"" }` }>
                    <div className="dot"></div>
                    <span>{data.set[index].name}</span>
                  </label>
                </>
              ))}
            </div>
        </>
      )}
    />
  );
}

export default SelectRadioQuestion;