import { useEffect, useState } from "react";
import { getCSVEvaluationData, loadExportCSVMetadata } from "./ExportService";

function ExportCSV({
                     slug
                   }) {

  const [metadataExport, setMetadataExport] = useState([]);

  useEffect(() => {
    async function loadMetadata() {
      const result = await loadExportCSVMetadata(slug);
      setMetadataExport(result);
    }
    loadMetadata().then(r =>
      console.log("Data loaded")
    );
  }, [slug]);

  return (
    <div className={ "export-csv" }>
      EXPORTY:
      <br/>
      { metadataExport.map((data, index) => {
        return (
          <button className={ "bt-header-navigation" }
                  onClick={ () => getCSVEvaluationData(data.slug, data._id, data.params) }>{ data.name }</button>
        );
      }) }
    </div>
  );
}

export default ExportCSV;
