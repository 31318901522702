import "../../App.css";
import { Link, useNavigate } from "react-router-dom";
import MainHeader from "../../MainHeader";
import withAuthentication from "../../Auth/withAuthentication";
import {
  loadExercise
} from "./CodingService";
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProgressBarCustom from "./ProgressBarCustom";
import authHeader from "../../service/auth-header";

function CodingSelect() {
  const navigate = useNavigate();
  const [disableButton, setDisableButton] = useState(false);
  const [progressBars, setProgressBars] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const headers = authHeader();
      fetch(`/api/evaluationProgress`, {
        method: "GET",
        headers,
      }).then(response => response.json()).then(response => setProgressBars(response.progress))
    }

    fetchData();
  }, []);

  const getNotEvaluatedExerciseAndRedirect = async (slug, periodRest) => {
    setDisableButton(true);
    const exerciseIdForCoder = await loadExercise(slug, periodRest);
    setDisableButton(false);
    if (exerciseIdForCoder.found) {
      navigate(`/kodovani/${ slug }/${ exerciseIdForCoder.response.entryId }/${ exerciseIdForCoder.response.evaluationId }`)
    } else {
      toast.info(exerciseIdForCoder.response.message)
      console.log(exerciseIdForCoder.response.message);
    }
  }

  return (
    <div className="App">
      <MainHeader/>
      <div className={ "content top" }>
        { progressBars &&
          <>
            <div>
              <div className={ "row center progress-wrapper" }>
                <ProgressBarCustom
                  all={ progressBars.progressCurrent.allEvaluation["co-kluci-provedli"] }
                  done={ progressBars.progressCurrent.doneEvaluation["co-kluci-provedli"] }
                  color={ "#F8AE01" } title={ "Minimum" }/>
                <ProgressBarCustom
                  all={ progressBars.progressCurrent.allEvaluation["co-kluci-provedli"] * 2 }
                  done={ progressBars.progressCurrent.doneEvaluation["co-kluci-provedli"] + (progressBars.progressCurrent.doneSecondEvaluation["co-kluci-provedli"] || 0) }
                  color={ "#007A61" } title={ "Celkově" }/>
              </div>
              <button
                className={ `card-button ${ disableButton ? "disabled" : "" }` }
                style={ { cursor: "pointer" } }
                onClick={ () => getNotEvaluatedExerciseAndRedirect("co-kluci-provedli", false) }
                disabled={ disableButton }
              >
                <div className={ "card-name" }>Co kluci provedli</div>
                <img
                  className={ "card-img" }
                  src={ `${ process.env.REACT_APP_ASSET_URL }cviceni-aktualni/assets/img/co-kluci-provedli/pic-00-1280w-thumb.jpg` }
                  alt="IMAGE"
                />
              </button>
            </div>

            <div>
              <div className={ "row center progress-wrapper" }>
                <ProgressBarCustom
                  all={ progressBars.progressCurrent.allEvaluation["proc-je-v-ceskoslovenske-skole"] }
                  done={ progressBars.progressCurrent.doneEvaluation["proc-je-v-ceskoslovenske-skole"] }
                  color={ "#F8AE01" } title={ "Minimum" }/>
                <ProgressBarCustom
                  all={ progressBars.progressCurrent.allEvaluation["proc-je-v-ceskoslovenske-skole"] * 2 }
                  done={ progressBars.progressCurrent.doneEvaluation["proc-je-v-ceskoslovenske-skole"] + (progressBars.progressCurrent.doneSecondEvaluation["proc-je-v-ceskoslovenske-skole"] || 0) }
                  color={ "#007A61" } title={ "Celkově" }/>
              </div>
              <button
                className={ `card-button ${ disableButton ? "disabled" : "" }` }
                style={ { cursor: "pointer" } }
                onClick={ () => getNotEvaluatedExerciseAndRedirect("proc-je-v-ceskoslovenske-skole", false) }
                disabled={ disableButton }
              >
                <div className={ "card-name" }>Proč je v československé škole
                </div>
                <img
                  className={ "card-img" }
                  src={ `${ process.env.REACT_APP_ASSET_URL }cviceni-aktualni/assets/img/proc-je-v-ceskoslovenske-skole/pic-00-1280w-thumb.jpg` }
                  alt="IMAGE"
                />
              </button>
            </div>
            { progressBars &&
              (progressBars.progressRest.allEvaluation["co-kluci-provedli"] || 0) !== 0  &&
              progressBars.progressCurrent.allEvaluation["co-kluci-provedli"] === progressBars.progressCurrent.doneEvaluation["co-kluci-provedli"] &&
              progressBars.progressCurrent.allEvaluation["proc-je-v-ceskoslovenske-skole"] === progressBars.progressCurrent.doneEvaluation["proc-je-v-ceskoslovenske-skole"] &&

              <div>
                <div className={ "row center progress-wrapper" }>
                  <ProgressBarCustom
                    all={ progressBars.progressRest.allEvaluation["co-kluci-provedli"] }
                    done={ progressBars.progressRest.doneEvaluation["co-kluci-provedli"] }
                    color={ "#F8AE01" } title={ "Minimum" }/>
                  <ProgressBarCustom
                    all={ progressBars.progressRest.allEvaluation["co-kluci-provedli"] * 2 }
                    done={ progressBars.progressRest.doneEvaluation["co-kluci-provedli"] + (progressBars.progressRest.doneSecondEvaluation["co-kluci-provedli"] || 0) }
                    color={ "#007A61" } title={ "Celkově" }/>
                </div>
                <button
                  className={ `card-button ${ disableButton ? "disabled" : "" } warning` }
                  style={ { cursor: "pointer" } }
                  onClick={ () => getNotEvaluatedExerciseAndRedirect("co-kluci-provedli", true) }
                  disabled={ disableButton }
                >
                  <div className={ "card-name" }>Nedodělané hodnocení z minulého období - Co kluci provedli
                  </div>
                  <img
                    className={ "card-img" }
                    src={ `${ process.env.REACT_APP_ASSET_URL }cviceni-aktualni/assets/img/co-kluci-provedli/pic-00-1280w-thumb.jpg` }
                    alt="IMAGE"
                  />
                </button>
              </div>
            }
            { progressBars &&
              (progressBars.progressRest.allEvaluation["proc-je-v-ceskoslovenske-skole"] || 0) !== 0 &&
              progressBars.progressCurrent.allEvaluation["proc-je-v-ceskoslovenske-skole"] === progressBars.progressCurrent.doneEvaluation["proc-je-v-ceskoslovenske-skole"] &&
              progressBars.progressCurrent.allEvaluation["co-kluci-provedli"] === progressBars.progressCurrent.doneEvaluation["co-kluci-provedli"] &&
              <div>
                <div className={ "row center progress-wrapper" }>
                  <ProgressBarCustom
                    all={ progressBars.progressRest.allEvaluation["proc-je-v-ceskoslovenske-skole"] }
                    done={ progressBars.progressRest.doneEvaluation["proc-je-v-ceskoslovenske-skole"] }
                    color={ "#F8AE01" } title={ "Minimum" }/>
                  <ProgressBarCustom
                    all={ progressBars.progressRest.allEvaluation["proc-je-v-ceskoslovenske-skole"] * 2 }
                    done={ progressBars.progressRest.doneEvaluation["proc-je-v-ceskoslovenske-skole"] + (progressBars.progressRest.doneSecondEvaluation["proc-je-v-ceskoslovenske-skole"] || 0) }
                    color={ "#007A61" } title={ "Celkově" }/>
                </div>
                <button
                  className={ `card-button ${ disableButton ? "disabled" : "" } warning` }
                  style={ { cursor: "pointer" } }
                  onClick={ () => getNotEvaluatedExerciseAndRedirect("proc-je-v-ceskoslovenske-skole", true) }
                  disabled={ disableButton }
                >
                  <div className={ "card-name" }>Nedodělané hodnocení z minulého období - Proč je v československé škole
                  </div>
                  <img
                    className={ "card-img" }
                    src={ `${ process.env.REACT_APP_ASSET_URL }cviceni-aktualni/assets/img/proc-je-v-ceskoslovenske-skole/pic-00-1280w-thumb.jpg` }
                    alt="IMAGE"
                  />
                </button>
              </div>
            }

          </>
        }
      </div>
    </div>
  );
}

export default withAuthentication(CodingSelect);