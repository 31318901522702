import "../coding/modules/scss/textAreaHl.scss";

function TextAreaHistoryLab({ data }) {
  const unescapeHTML = (str) => {
    if (str === null) str = "";
    return str.replace(
      /&amp;|&lt;|&gt;|&#39;|&quot;/g,
      (tag) =>
        ({
          "&amp;": "&",
          "&lt;": "<",
          "&gt;": ">",
          "&#39;": "'",
          "&quot;": '"',
        }[tag] || tag)
    );
  };

  return (
    <div className={"text-area-hl-main"}>
      {data && (
        <div className={"text-area-hl-header"}>{data.zadani.hlavni}</div>
      )}
      {data &&
        data.uzivatelskyText.otazky.map((textHL, index) => {
          return (
            <div className={"questions"} key={`textAreaHl-${index}`}>
              <div>{textHL.zadani}</div>
              <div className={"text-area-hl"}>
                {unescapeHTML(textHL.answer)}
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default TextAreaHistoryLab;