import withAuthentication from "../../Auth/withAuthentication";
import { Link } from "react-router-dom";
import "../account/accountPage.scss";
import ExportCSV from "./ExportCSV";
import ExportData from "./ExportData";
import "./exportPage.scss";

function ExportPage() {
  return (
    <div className={"account-page"}>
      <div className="coding-header">
        <Link to="/">
          <button className={"bt-header-navigation"}>hlavní stránka</button>
        </Link>
      </div>
      <div className={"export-main"}>
        <div className={"export-exercise"}>
          <div className={"label"}>Co kluci provedli</div>
          <ExportCSV slug={"co-kluci-provedli"}/>
          <ExportData slug={"co-kluci-provedli"}/>
        </div>
        <div className={"export-exercise"}>
          <div>Proč je v československé škole</div>
          <ExportCSV slug={"proc-je-v-ceskoslovenske-skole"}/>
          <ExportData slug={"proc-je-v-ceskoslovenske-skole"}/>
        </div>
      </div>
    </div>
  );
}

export default withAuthentication(ExportPage);
