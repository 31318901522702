import "./scss/questionsInputs.scss";
function NumberQuestion({ data, values, handleChange }) {
  return (
    <>
      <label htmlFor={data.id} className={"sq-name"}>
        {data.name}
      </label>
      <div className={"wrapper-buttons center"}>
        <input
          type="number"
          id={data.id}
          name={data.id}
          onChange={handleChange}
          min="0"
          value={values[data.id] ? values[data.id] : 0}
          className={"number-input"}
        />
      </div>
    </>
  );
}

export default NumberQuestion;