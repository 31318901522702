import "./headerRow.scss";

function HeaderRow({ evaluationQuestions }) {
  if (!evaluationQuestions) return <></>;
  // const empiric = evaluationQuestions.response.empiricQuestions;
  const slides = evaluationQuestions.evaluationQuestions;
  return (
    <div className="evaluation-header-row">
      <div>
        <div className={"slide-coder"}>Kodér</div>
      </div>
      <div>
        <div className={"slide-time"}>Start</div>
      </div>
      <div>
        <div className={"slide-time"}>End</div>
      </div>
      {slides &&
        slides.map((slide, indexSlide) => {
          return (
            <div className={"slide-data"} key={indexSlide}>
              {slide.questions.map((questions, indexQuestion) => {
                if (questions.input === "select")
                  return questions.set.map((select, indexQuestionSelect) => (
                    <span title={`${select.name}`} className={"slide-select"} key={indexQuestionSelect}>
                      <div className={"slide-question"}>{questions.id}</div>
                    </span>
                  ));
                return (
                  <div title={`${questions.name}`} className={"slide-question"} key={indexQuestion}>
                    {questions.id}
                  </div>
                );
              })}
            </div>
          );
        })}
    </div>
  );
}

export default HeaderRow;