import { getCSVEvaluationData, loadEvaluationData } from "./AccountService";
import { useEffect, useState } from "react";
import withAuthentication from "../../Auth/withAuthentication";
import { loadEvaluationQuestions } from "../coding/CodingService";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./accountPage.scss";
import { Field, FormikProvider, useField, useFormik } from "formik";
import authHeader from "../../service/auth-header";
import { toast } from "react-toastify";

function NewEvaluationPeriodPage() {
  let { slug } = useParams();
  const navigate = useNavigate();
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [notAddedEntryId, setNotAddedEntryId] = useState(null);

  const formik = useFormik({
    initialValues: {
      name: "",
      slug,
      exerciseIDs: "",
      enable: false,
      coverage: "all"
    },
    onSubmit: async values => {
      const headers = authHeader();
      headers["Content-Type"] = "application/json";
      setDisableSubmit(true);
      let response = await fetch(`/api/evaluationPeriod`, {
        method: "POST",
        headers,
        body: JSON.stringify(values),
      });
      response = await response.json();
      if (response.notAddedEntryId) {
        setNotAddedEntryId(response.notAddedEntryId)
        toast.info(response.message);
        setTimeout(()=>{
          navigate('/administrace');
        },5000)

      } else {
        toast.info(response.message);
        navigate('/administrace');
      }
    },
  });


  return (
    <div className={ "account-page" }>
      <div className="coding-header">
        <button
          className={ "button" }
          onClick={ () => {
            localStorage.removeItem("user");
            window.location.reload();
          } }
        >
          Odhlásit se{ " " }
        </button>
        <div style={ { display: "flex" } }>
          Přidat periodu
        </div>
        <div className={ "row" }>
          <Link className={ "no-link" } to="/administrace">
            <button className={ "button" }>Administrace</button>
          </Link>
          <Link className={ "no-link" } to="/">
            <button className={ "button" }>Hlavní stránka</button>
          </Link>
        </div>
      </div>

      <FormikProvider value={ formik }>
        <div className={ "content top" }>
          <form onSubmit={ formik.handleSubmit } className={"column gap-small"}>
            <div className={ "row" }>
              <label htmlFor="enable" className="checkbox">
                Aktivovat
                <input
                  id="enable"
                  name="enable"
                  type="checkbox"
                  onChange={ formik.handleChange }
                  value={ formik.values.enable }
                />
                <div className="checkbox__indicator"></div>
              </label>
            </div>
            <div className={ "row gap-small" }>
              <div>Jméno:</div>
              <input
                id="name"
                name="name"
                type="text"
                onChange={ formik.handleChange }
                value={ formik.values.name }
              />
            </div>
            <div role="group" aria-labelledby="my-radio-group" className={"row gap-small"}>
              <label className={"row gap-mini"} style={{cursor:"pointer"}}>
                <Field type="radio" name="coverage" value="all"/>
                Plné
              </label>
              <label className={"row gap-mini"} style={{cursor:"pointer"}}>
                <Field type="radio" name="coverage" value="one"/>
                Po jednotlivcích
              </label>
            </div>
            <Field component="textarea" label="exerciseIDs"
                   name="exerciseIDs"
                   placeholder="Sem dejte všechny entryId cvičení, oddělené čárkou. Příklad: 145,478,589,365,215"
                   rows="20"
                   className={"period-textarea"}

            />
            <div>
              <button type="submit" disabled={ disableSubmit } className={"button save"}>Uložit</button>
            </div>
            { notAddedEntryId && <div>
              <div>za chvíli budete přesměrováni</div>
              EntryId které nebyly přidány: { JSON.stringify(notAddedEntryId) }
            </div> }
          </form>
        </div>
      </FormikProvider>
    </div>
  );
}

export default withAuthentication(NewEvaluationPeriodPage);