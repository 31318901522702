import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import RatingForm from "./RatingForm";

function RatingEvaluationPage() {

    const [exercises, setExercises] = useState([]);
    let { userId, slug, entryId } = useParams();
    const [heightPictures, setHeightPictures] = useState(35);

    useEffect(() => {
        fetch(`/api/rating/ratingExercises`).then(response => response.json()).then(response => setExercises(response))
    }, []);


    let exerciseRow = [];
    if (exercises.length > 0) {
        const exercise = exercises.find(e => e.entryId === parseInt(entryId));
        let picArrayRow = []
        for (let i = 0; i < exercise.urls.length; i++) {
            picArrayRow.push(
                <div>
                    <img style={{ height: `${heightPictures}rem` }}
                         className={"c-image"}
                         src={`${exercise.urls[i]}`} alt=""
                         loading="lazy"/>
                </div>
            );
        }
        exerciseRow.push(<div className={"row"}><a
            href={`https://www.historylab.cz/cviceni/prohlizeni/${slug}?entryId=${exercise.entryId}`}>{exercise.entryId}</a>{picArrayRow}
        </div>)

    }

    return (
        <div className="evaluation-page">
            <input type="range" min="5" max="100" defaultValue={35}
                   onChange={(e) => setHeightPictures(e.target.value)}/>
            {exerciseRow}
            <RatingForm userId={userId} slug={slug} entryId={entryId} />
        </div>
    );
}

export default RatingEvaluationPage;
