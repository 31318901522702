import "./mainHeader.scss";
import { Link } from "react-router-dom";

function MainHeader() {
  const user = JSON.parse(localStorage.getItem("user"));
  const isAdmin = user.roles.some((role) => role === "ROLE_ADMIN");
  console.log("=>(MainHeader.js:7) isAdmin", isAdmin);

  return (
    <div className="main-header">
      <button
        className={ "button" }
        onClick={ () => {
          localStorage.removeItem("user");
          window.location.reload();
        } }
      >
        Odhlásit se{ " " }
      </button>
      <div>Uživatel: { user.username }</div>
      <div className={"row"}>
        <Link className={ "no-link" } to="/">
          <button className={ "button" }>Hlavní stránka</button>
        </Link>
        {isAdmin && <Link className={ "no-link" } to="/administrace">
          <button className={ "button" }>Administrace</button>
        </Link>
        }
      </div>
    </div>
  );
}

export default MainHeader;