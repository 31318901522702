import "./App.css";
import { Link } from "react-router-dom";
import withAuthentication from "./Auth/withAuthentication";
import { ApplicationStateConsumer } from "./Auth/ApplicationStateProvider";
import MainHeader from "./MainHeader";

function App() {
  // const [token, setToken] = useState(null);
  // console.log("-> token", token);

  // let user = localStorage.getItem("user");
  // if (user && !token) setToken(JSON.parse(user).accessToken);
  //
  // if (!token) {
  //   return <LoginPage setToken={setToken} />;
  // }

  return (
    <ApplicationStateConsumer>
      { ({ authenticated, login, user }) => (

        <div className="App">
          <MainHeader/>
          <div className={"row"}>
          { user.roles.some(r => (r === "ROLE_ADMIN") || (r === "ROLE_CODER" ))
            &&
            <Link className={"no-link"} to="/kodovani">
              <button className={ "button big" }>Kódování</button>
            </Link>
          }

          { user.roles.some(r => (r === "ROLE_ADMIN") || (r === "ROLE_OPTIMIZER" ))
            &&
            <Link className={"no-link"} to="/srovnani">
              <button className={ "button big" }>Srovnání</button>
            </Link>
          }
          </div>
          {/*<Link to="/rating">*/ }
          {/*  <button className={"bt-navigation"}>Rating</button>*/ }
          {/*</Link>*/ }
        </div>
      ) }
    </ApplicationStateConsumer>
  );
}

export default withAuthentication(App);