import HeaderRow from "./HeaderRow";
import "./evaluationRow.scss";
import CoderRow from "./CoderRow";
import authHeader from "../../service/auth-header";
import { toast } from "react-toastify";

function EvaluationRow({
                         evaluationData,
                         slug,
                         evaluationQuestions,
                         evaluationPeriodId,
                         fetchData
                       }) {

  if (evaluationData.length === 0) return;
  evaluationData.sort((a, b) => {
    return a.coderName.localeCompare(b.coderName)
  });
  const removeEvaluationOfId = (entryId) => {
    if (window.confirm(`Opravdu chcete odstranit kódování cvičení ${ entryId }?`)) {
      const headers = authHeader();
      fetch(`/api/evaluation/${ entryId }/${ evaluationPeriodId }`, {
        method: "DELETE",
        headers,
      }).then(response => response.json()).then(response => {
        toast.info(response)
        console.log(response);
        fetchData();
      })
    }
  }

  const unCodableToggle = (entryId, text) => {
    if (window.confirm(`Opravdu chcete označit cvičení ${ entryId } jako ${text}?`)) {
      const headers = authHeader();
      fetch(`/api/evaluationExerciseUnCodable/${ entryId }`, {
        method: "PUT",
        headers,
      }).then(response => response.json()).then(response => {
        toast.info(response)
        console.log(response);
        fetchData();
      })
    }
  }

  const startCodingById = (entryId, evaluationId) => {
    window.open(`/kodovani/${ slug }/${ entryId }/${ evaluationId }`, '_blank')
  }

  return (
    <div
      className={ `evaluation-row ${ evaluationData[0].exercise ? "" : "exercise-missing" } ${ evaluationData[0].unCodable ? "exercise-uncodable" : "" }` }>
      <div className={ "evaluation-header" }>
        <div className={"item entry-id"}>
          <a
            href={ `https://www.historylab.cz/cviceni/prohlizeni/${ slug }?entryId=${ evaluationData[0].entryId }` }
            target="_blank"
            className={ "link" }
          >
            { evaluationData[0].entryId }
          </a>
        </div>
        <div
          className={ 'item' }>Priorita: { evaluationData[0].priority }</div>
        <div className={ `item ${evaluationData[0].exercise ?"danger":""}` }>Cvičení nalezeno v
          databázi: { evaluationData[0].exercise ? "ano" : "NE" }</div>
        <div className={ 'item blank' }>
          <button className={ "button danger small" }
                  onClick={ () => removeEvaluationOfId(evaluationData[0].entryId) }>Odstranit
            hodnocení
            cvičení { evaluationData[0].entryId }
          </button>
        </div>
        <div className={ 'item blank' }>
          <button className={ "button small" }
                  onClick={ () => startCodingById(evaluationData[0].entryId, evaluationData[0]._id) }>
            Spustit kodování jako současný uživatel
          </button>
        </div>
        <div className={ 'item blank' }>
          <button className={ "button small" }
                  onClick={ () => unCodableToggle(evaluationData[0].entryId, `${evaluationData[0].unCodable? "kódovatelné":"nekódovatelné"}`) }>
            { evaluationData[0].unCodable? "Označit jako kódovatelné":"Označit jako nekódovatelné" }
          </button>
        </div>
        { evaluationData[0].unCodable && <div className={ 'item' } title={`${evaluationData[0].unCodableUser}`}>
          NEKÓDOVATELNÉ
        </div>
        }
      </div>
      { evaluationQuestions && (
        <HeaderRow
          evaluationQuestions={ evaluationQuestions }
        />
      ) }
      { evaluationData.map((evaluation, index) => {
        return (
          <CoderRow
            key={ index }
            evaluation={ evaluation }
            evaluationQuestions={ evaluationQuestions }
            fetchData={fetchData}
          />
        );
      }) }
    </div>
  );
}

export default EvaluationRow;