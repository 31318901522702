import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

import "./RatingEvaluationSelectPage.css";

function RatingEvaluationSelectPage() {

    const [exercises, setExercises] = useState([]);
    const [loading, setLoading] = useState(true);
    const [userMetadata, setUserMetadata] = useState(null);
    let {userId} = useParams();
    const navigate = useNavigate();
    useEffect(async () => {
        await fetch(`/api/rating/user/${userId}`).then(response => response.json()).then(response => {
            console.log("-> response", response);
            setUserMetadata(response)
        });
        await fetch(`/api/rating/ratingExercises`).then(response => response.json()).then(response => setExercises(response));
        setLoading(false);
    }, [userId]);

    const redirectCP = (slug, entryId) => {
        navigate(`/rating/evaluation/${userId}/${slug}/${entryId}`);
    }


    let exerciseComponents = [];
    if (!loading) {

        const alreadyRated = (entryId, arrayOfRatedExercises) => {
            let compareFunction = (e) => entryId === e;
            return arrayOfRatedExercises.some(compareFunction);
        }

        for (let i = 0; i < exercises.length; i++) {
            let disabledExercise = alreadyRated(exercises[i].entryId, userMetadata.ratedExercises);
            console.log("disabledExercise", disabledExercise);
            exerciseComponents.push(<div>
                <button className={`card ${disabledExercise ? "disabled" : ""}`} disabled={disabledExercise}>
                    <div className={"exercise-id"}>
                        {exercises[i].entryId}
                    </div>
                    <img src={`${exercises[i].thumb}`}
                         alt={`${exercises[i].slug}`}
                         onClick={() => redirectCP(exercises[i].slug, exercises[i].entryId)}/>
                </button>
            </div>);
        }
    }

    return (
        <div className="rating-selection-page">
            {loading && <div>LOADING</div>}
            {exerciseComponents}
        </div>
    );
}

export default RatingEvaluationSelectPage;