import {Formik, Field} from 'formik';
import questionsTemplate from "./questionsTemplate.json";
import {useNavigate} from "react-router-dom";
import "./RatingForm.css"

function RatingForm({userId, slug, entryId}) {
    const navigate = useNavigate();

    let questionsRender = [];
    for (let i = 0; i < questionsTemplate.length; i++) {
        let questionType = questionsTemplate[i];
        let arrayQuestionType = []
        for (let j = 0; j < questionType.questions.length; j++) {
            let question = questionType.questions[j];
            arrayQuestionType.push(
                <div className={"question"}>
                    <Field
                        type="checkbox"
                        name="questions"
                        value={`${question.id}`}
                    />
                    <div className={"q-text"}>{question.question}</div>
                </div>
            )
        }
        questionsRender.push(
            <div className={"type-name"}>
                <div className={"question-type-header"} style={{"font-size": "2rem"}}>{questionType.type}</div>
                <div className={"question-content"}>{arrayQuestionType}</div>
            </div>
        )
    }

    const initialValues = {
        entryId: entryId,
        userId: userId,
        slug: slug
    }

    const submitForm = async (values) => {
        fetch('/api/rating/submit-form', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(values),
        }).then(data => {
            console.log('Success:', data);
            navigate(`/rating/evaluation/${userId}`)
        }).catch((error) => {
            console.error('Error:', error);
        });
    }


    return (
        <div className="rating-form">
            <Formik
                initialValues={initialValues}
                onSubmit={async (values, {setSubmitting}) => {
                    setSubmitting(true);
                    await submitForm(values);
                    setSubmitting(false);
                    // setTimeout(() => {
                    //     alert(JSON.stringify(submitData, null, 2));
                    //     setSubmitting(false);
                    // }, 400);
                }}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit}>
                        {questionsRender}
                        <div className={"submit-button"}>
                            <button  type="submit" disabled={isSubmitting}>
                                ODESLAT
                            </button>
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    );
}

export default RatingForm;