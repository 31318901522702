import "./scss/slideEvaluation.scss";
import NumberQuestion from "./questionInputs/NumberQuestion";
import TextQuestion from "./questionInputs/TextQuestion";
import BooleanQuestion from "./questionInputs/BooleanQuestion";
import SelectCheckboxQuestion from "./questionInputs/SelectCheckboxQuestion";
import SelectRadioQuestion from "./questionInputs/SelectRadioQuestion";

function ExerciseEvaluation({
  evaluationSlideQuestions,
  evaluationValuesSlide,
  onChangeInput,
  setFieldValue,
  wordCountArray,
}) {

  const renderQuestion = (question) => {

    if(question.dependencyShow){
      const {id, value} = question.dependencyShow;
      if( evaluationValuesSlide[id] !== value) return <></>;
    }

    switch (question.input) {
      case "number":
        return (
          <NumberQuestion
            data={question}
            values={evaluationValuesSlide}
            handleChange={onChangeInput}
          />
        );
      /* falls through */
      case "select":
        if (question.type === "checkbox")
          return (
            <SelectCheckboxQuestion
              data={question}
              values={evaluationValuesSlide}
              handleChange={onChangeInput}
            />
          );
        if (question.type === "radio")
          return (
            <SelectRadioQuestion
              data={question}
              values={evaluationValuesSlide}
              handleChange={onChangeInput}
              setFieldValue={setFieldValue}
              numberOfWords={wordCountArray[question.countWord]}
            />
          );
      /* falls through */
      case "boolean":
        return (
          <BooleanQuestion
            data={question}
            values={evaluationValuesSlide}
            handleChange={onChangeInput}
          />
        );
      /* falls through */
      case "text":
        return (
          <TextQuestion
            data={question}
            values={evaluationValuesSlide}
            handleChange={onChangeInput}
          />
        );
      /* falls through */
      default:
        return (
          <TextQuestion
            data={question}
            values={evaluationValuesSlide}
            handleChange={onChangeInput}
          />
        );
    }
  };

  // const lastQuestions = evaluationSlideQuestions.slide === 0;
  return (
    <div className={`slide-evaluation-questions`}>
      <div className={"sticky-q"}>
        {evaluationSlideQuestions &&
          evaluationSlideQuestions.questions.map((q, index) => {
            return (
              <div key={index} className={"se-question"}>
                {renderQuestion(q)}
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default ExerciseEvaluation;