import { FieldArray } from "formik";
import "./scss/questionsInputs.scss";

function SelectCheckboxQuestion({ data, values, handleChange }) {
  return (
    <FieldArray
      name={`${data.id}`}
      render={(arrayHelpers) => (
        <div className={"wrapper-buttons"}>
          {data.set &&
            data.set.map((selectElement, index) => (
              <>
              <input
                type="checkbox"
                id={data.set[index].id}
                name={data.set[index].id}
                onChange={handleChange}
                defaultChecked={values[data.set[index].id]}
                value={values[data.set[index].id]}
                className={"sq-input"}
              />
              <label
                key={index}
                className={`option ${
                  values[data.set[index].id] ? "checked" : ""
                }`}
                htmlFor={data.set[index].id}
              >
                <span>{data.set[index].name}</span>
              </label>
              </>
            ))}
        </div>
      )}
    />
  );
}

export default SelectCheckboxQuestion;