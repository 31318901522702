import "./scss/questionsInputs.scss";

function BooleanQuestion({ data, values, handleChange }) {
  return (
    <>
      <div className={ "sq-name" }>{ data.name }</div>
      <div className={ "wrapper-buttons center" }>
        <input
          type="checkbox"
          id={ data.id }
          name={ data.id }
          onChange={ handleChange }
          // onBlur={handleBlur}
          defaultChecked={ values[data.id] }
          value={ values[data.id] }
          className={ "sq-input" }
        />
        <label htmlFor={ data.id }
               className={ `option ${ values[data.id] ? "checked" : "" }` }>
        <span
        >
          { values[data.id] ? "ANO!" : "ano?" }
        </span>
        </label>
      </div>
    </>
  );
}

export default BooleanQuestion;