import React from 'react'
import ProgressBar from 'react-customizable-progressbar'
import "./progressBarCustom.scss"

const ProgressBarCustom = ({ progress, color, title, all, done }) => (
  <div className="item">
    <div className="title">
      <span>{ title }</span>
    </div>
    <ProgressBar
      radius={50}
      progress={( done || 0) / (all || 0) * 100}
      strokeWidth={10}
      strokeColor={ color }
      strokeLinecap="butt"
      trackStrokeWidth={10}
      trackStrokeLinecap="butt"
      cut={120}
      rotate={-210}
    >
      <div className="indicator">
        <div>{done || 0}/{all || 0}</div>
      </div>
    </ProgressBar>
  </div>
)

export default ProgressBarCustom