import { useState, useEffect } from "react";
import "../account/accountPage.scss";
import { loadExportOverview } from "./ExportService";

function ExportData({
                      slug
                    }) {

  const [overview, setOverview] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadMetadata() {
      setLoading(true);
      const result = await loadExportOverview(slug);
      console.log("=>(ExportData.js:14) result", result);
      setOverview(result);
    }

    loadMetadata().then(r => {
        setLoading(false);
        console.log("Data loaded")
      }
    );
  }, [slug]);

  return (
    <div className={ "export-data" }>
      DATA:
      {loading && <div>Načítám...</div>}
      { overview && overview.allByPriority.map((priority, index) => {
        return <div className={ "export-overview-all-row" }>
          Priorita - hotové/všechny:
          <br />
          &ensp;&ensp; { index + 1 } - { priority.done } / { priority.all }
        </div>
      }) }
      <br/>
      { overview && overview.allByCoder.map((coder, index) => {
        return <div className={ "export-overview-all-row" }>
          Kodér: { coder.username }
          <br/>
          &ensp;&ensp; hotové/všechny: { coder.done } / { coder.all }
          <br/>
        </div>
      }) }
    </div>
  );
}

export default ExportData;
