import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import questionsTemplate from "./questionsTemplate.json";
import "./RatingResultExercisePage.css";

function RatingResultExercisePage() {
  const [countExercise, setCountExercise] = useState([]);
  const [loading, setLoading] = useState(true);
  // const navigate = useNavigate();
  let { entryId, slug } = useParams();

  useEffect(() => {
    const getData = async () => {
      await fetch(`/api/rating/result/${slug}/${entryId}`)
        .then((response) => response.json())
        .then((response) => setCountExercise(response));
    };
    getData().then(() => setLoading(false));
  }, [entryId, slug]);

  let questionsRender = [];
  for (let i = 0; i < questionsTemplate.length; i++) {
    let questionType = questionsTemplate[i];
    let arrayQuestionType = [];
    for (let j = 0; j < questionType.questions.length; j++) {
      let question = questionType.questions[j];
      arrayQuestionType.push(
        <div className={"question"}>
          <div className={"count"}>{countExercise[question.id]}</div>
          <div className={"q-text"}>{question.question}</div>
        </div>
      );
    }
    questionsRender.push(
      <div className={"type-name"}>
        <div className={"question-type-header"} style={{ "font-size": "2rem" }}>
          {questionType.type}
        </div>
        <div className={"question-content"}>{arrayQuestionType}</div>
      </div>
    );
  }

  return (
    <div className="rating-form">
      <div>Číslo cvičení: {entryId}</div>
      {loading && questionsRender}
    </div>
  );
}

export default RatingResultExercisePage;
