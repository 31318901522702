import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../RatingResultSelectPage.css";

function RatingResultAllSelectPage() {
  const [exercises, setExercises] = useState([]);
  const [loading, setLoading] = useState(true);

  // const [dataReset, setDataReset] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const getData = async () => {
      await fetch(`/api/rating/ratingExercises`)
        .then((response) => response.json())
        .then((response) => setExercises(response));
    };
    getData().then(() => setLoading(false));
  }, []);

  const redirectCP = (slug, entryId) => {
    navigate(`/rating/result-all/${slug}/${entryId}`);
  };

  let exerciseComponents = [];
  if (!loading) {
    for (let i = 0; i < exercises.length; i++) {
      exerciseComponents.push(
        <div>
          <button className={`card`} style={{ cursor: "pointer" }}>
            <div className={"exercise-id"}>{exercises[i].entryId}</div>
            <img
              src={`${exercises[i].thumb}`}
              alt={`${exercises[i].slug}`}
              onClick={() =>
                redirectCP(exercises[i].slug, exercises[i].entryId)
              }
            />
          </button>
        </div>
      );
    }
  }

  return <div className="rating-selection-page">{exerciseComponents}</div>;
}

export default RatingResultAllSelectPage;
