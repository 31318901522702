import './ComparisonPage.scss';
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import MainHeader from "../../MainHeader";
import withAuthentication from "../../Auth/withAuthentication";
import authHeader from "../../service/auth-header";
import TextAreaHistoryLab from "../modules/TextAreaHistoryLab";
import SvgHistoryLab from "../modules/SvgHistoryLab";


function ComparisonPage() {

  let { slug } = useParams();
  useEffect(() => {
    async function fetchData() {
      const headers = authHeader();
      fetch(`/api/comparison/exercise/${ slug }`, {
        method: "GET",
        headers,
      }).then(response => response.json()).then(response => setAllDataExercises(response))
    }

    fetchData();
  }, []);
  const [selectedModules, setSelectedModules] = useState([]);
  const [exerciseAllData, setAllDataExercises] = useState([]);

  const [svgWidth, setSvgWidth] = useState(30);

  const generateMetadataComponent = (exercise) => (
    <div className={ "exercise-metadata" }>
      <div style={ { "font-weight": "bold" } }>Cvičení s číslem</div>
      <div>{ exercise.entryId }</div>
      <div style={ { "font-weight": "bold" } }>Verze cvičení</div>
      <div>{ exercise.exerciseVersion }</div>
      <Link className={ "link" } style={ { textDecoration: 'none' } }
            to={ `${ process.env.REACT_APP_EXERCISE_URL }${ slug }?entryId=${ exercise.entryId }&anon=1` }
            target="_blank">
        <button style={ { textDecoration: 'none' } }
                className={ `peeping-select-bt` }>
          Link na cvičení
        </button>
      </Link>
    </div>

  )

  const generateComparison = () => {
    return exerciseAllData.data.map(exercise => (
      <div className={ "comparison-row" }>
        { selectedModules.length !== 0 && generateMetadataComponent(exercise) }
        { selectedModules.map(moduleId => {
          const q = exercise.questions[moduleId][0];
          if (q.type === "svg") return <div
            style={ { width: `${ svgWidth }rem` } }><SvgHistoryLab
            data={ q.svg.soubory }/></div>
          if (q.type === "uzivatelskyText") return <div
            style={ { width: `${ svgWidth }rem` } }><TextAreaHistoryLab
            data={ q }/></div>
        }) }
      </div>
    ))


  }

  const changeSelectedModules = (id) => {
    if (selectedModules.some(e => e === id)) {
      const index = selectedModules.indexOf(id);
      selectedModules.splice(index, 1);
      setSelectedModules([...selectedModules]);
    } else {
      setSelectedModules([...selectedModules, id]);
    }
  }

  const deleteAllSelectedModules = () => setSelectedModules([]);


  return (
    <div className="comparison-page">
      <MainHeader/>
      <div className={ "comparison-main" }>
        <div className={ "select-module" }>
          { exerciseAllData.length !== 0 && exerciseAllData.exportStructure.map(structure => (
            <button
              className={ `peeping-select-bt ${ selectedModules.some(moduleId => structure.id === moduleId) ? "selected" : "" }` }
              onClick={ () => changeSelectedModules(structure.id) }>{ `${ structure.id } - ${ structure.module }` }</button>
          )) }
          |
          <button className={ `peeping-select-bt reset` }
                  onClick={ () => deleteAllSelectedModules() }>
            Obnovení výběru
          </button>
          {/*{header}*/ }
          <input type="range" min="25" max="100" defaultValue={ 35 }
                 onChange={ (e) => setSvgWidth(e.target.value) }/>
        </div>
        { exerciseAllData.length !== 0 &&
          <div className={ "comparison-grid" }>
            { generateComparison() }
          </div> }
      </div>
    </div>
  );
}

export default withAuthentication(ComparisonPage);