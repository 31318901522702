import './ComparisonPage.scss';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import withAuthentication from "../../Auth/withAuthentication";
import MainHeader from "../../MainHeader";
import authHeader from "../../service/auth-header";

function CPSelectExercise() {
  const [dataExercises, setDataExercises] = useState([]);
  let navigate = useNavigate();
  useEffect(() => {
    async function fetchData() {
      const headers = authHeader();
      fetch(`/api/comparison/exercises`, {
        method: "GET",
        headers,
      }).then(response => response.json()).then(response => setDataExercises(response))
    }
    fetchData();
  }, []);

  const redirectCP = (slug) => navigate(`/srovnani/${ slug }`);

  return (
    <div className="comparison-select-page">
      <MainHeader/>
      {
        dataExercises.map(e => (
          <div key={e.exerciseSlug} >
            <button className={"card-button"} style={ { cursor: "pointer" } }  onClick={ () => redirectCP(e.exerciseSlug)}>
              <div className={"card-name"} >{ e.exerciseName }</div>
              <img
                className={"card-img"}
                src={ `${process.env.REACT_APP_ASSET_URL}cviceni-aktualni/assets/img/${e.mainPictureThumb}` }
                alt="IMAGE"
                />
            </button>
          </div>
        ))
      }
    </div>
  );
}

export default withAuthentication(CPSelectExercise);