import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import questionsTemplate from "../questionsTemplate.json";
// import "../RatingResultExercisePage.css";
import "./RatingResultAllExercisePage.css";

function RatingResultAllExercisePage() {
  const [countExercise, setCountExercise] = useState([]);
  const [loading, setLoading] = useState(true);
  // const navigate = useNavigate();
  let { entryId, slug } = useParams();

  useEffect(() => {
    const getData = async () => {
      await fetch(`/api/rating/result-all/${slug}/${entryId}`)
        .then((response) => response.json())
        .then((response) => setCountExercise(response));
    };
    getData().then(() => setLoading(false));
  }, [entryId, slug]);

  let headerIds = [];
  let questionsRender = [];
  for (let j = 0; j < countExercise.length; j++) {
    headerIds.push(
      <div className={"count tooltip"}>
        {countExercise[j].userId}
        <span className="tooltiptext">{countExercise[j].userName}</span>
      </div>
    );
  }
  headerIds.push(
    <div className={"count tooltip"}>
      D<span className="tooltiptext">Součet v řádku</span>
    </div>
  );

  for (let i = 0; i < questionsTemplate.length; i++) {
    let questionType = questionsTemplate[i];
    let arrayQuestionType = [];

    for (let j = 0; j < questionType.questions.length; j++) {
      let question = questionType.questions[j];

      let answers = [];
      let countAll = 0;
      for (let k = 0; k < countExercise.length; k++) {
        const findCheck = (e) => e === question.id;
        const checked = countExercise[k].questions.some(findCheck);
        if (checked) countAll++;
        answers.push(
          <div className={`count ${checked ? "checked" : ""}`}>
            {checked ? "1" : "-"}
          </div>
        );
      }
      answers.push(<div className={`count all`}>{countAll}</div>);

      arrayQuestionType.push(
        <div className={"question"}>
          <div className={"answers"}>{answers}</div>
          <div className={"q-text"}>{question.question}</div>
        </div>
      );
    }
    questionsRender.push(
      <div className={"type-name"}>
        <div className={"question-type-header"} style={{ "font-size": "2rem" }}>
          {questionType.type}
        </div>

        <div className={"answers"}>{headerIds}</div>
        <div className={"question-content"}>{arrayQuestionType}</div>
      </div>
    );
  }

  return (
    <div className="rating-form">
      <div>Číslo cvičení: {entryId}</div>
      {!loading && questionsRender}
    </div>
  );
}

export default RatingResultAllExercisePage;