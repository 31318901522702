import "./scss/exerciseRow.scss";
import ExerciseSlide from "./ExerciseSlide";

function ExerciseRow({
  slides,
  evaluationQuestions,
  evaluationValues,
  onChangeInput,
  setFieldValue,
}) {
  return (
    <div className="exercise-row">
      {slides.length !== 0 &&
        slides.map((slide, index) => {
          if (slide.type === "endQuestion") return null;
          return (
            <ExerciseSlide
              slideData={slide}
              evaluationSlideQuestions={evaluationQuestions[index]}
              key={`exercise-slide-${index}`}
              evaluationValuesSlide={evaluationValues}
              onChangeInput={onChangeInput}
              setFieldValue={setFieldValue}
            />
          );
        })}
    </div>
  );
}

export default ExerciseRow;
