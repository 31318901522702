import "./scss/textAreaHl.scss";
import { useEffect } from "react";

function TextAreaHl({ data, setWordCountArray }) {
  const unescapeHTML = (str) => {
    if (str === null) str = "";
    return str.replace(
      /&amp;|&lt;|&gt;|&#39;|&quot;/g,
      (tag) =>
        ({
          "&amp;": "&",
          "&lt;": "<",
          "&gt;": ">",
          "&#39;": "'",
          "&quot;": '"',
        }[tag] || tag)
    );
  };

  useEffect(() => {
    const countWords = (str) => {
      let replaceString = str;
      replaceString = replaceString.replaceAll(",", " ");
      replaceString = replaceString.replaceAll(".", " ");
      replaceString = replaceString.replaceAll("?", " ");
      replaceString = replaceString.replaceAll("!", " ");
      replaceString = replaceString.replaceAll("/", " ");
      replaceString = replaceString.replaceAll(":", " ");
      let splitTextArea = replaceString.split(" ");
      splitTextArea = splitTextArea.filter((word) => word !== "");
      let wordsCount = splitTextArea.length;
      setWordCountArray((oldArray) => [...oldArray, wordsCount]);
    };

    data.uzivatelskyText.otazky.map((textHL, index) => {
      const unescapedHTML = unescapeHTML(textHL.answer);
      countWords(unescapedHTML);
    });
  }, [data.uzivatelskyText.otazky]);

  return (
    <div className={"text-area-hl-main"}>
      {data && (
        <div className={"text-area-hl-header"}>{data.zadani.hlavni}</div>
      )}
      {data &&
        data.uzivatelskyText.otazky.map((textHL, index) => {
          return (
            <div className={"questions"} key={`textAreaHl-${index}`}>
              <div>{textHL.zadani}</div>
              <div className={"text-area-hl"}>
                {unescapeHTML(textHL.answer)}
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default TextAreaHl;