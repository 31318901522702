import "./scss/exerciseSlide.scss";
import SvgHl from "./modules/SvgHl";
import TextAreaHl from "./modules/TextAreaHl";
import SlideEvaluation from "./SlideEvaluation";
import { useState } from "react";

function ExerciseSlide({
  slideData,
  evaluationSlideQuestions,
  evaluationValuesSlide,
  onChangeInput,
  setFieldValue,
}) {
  const [wordCountArray, setWordCountArray] = useState([]);

  const renderSlide = (slideData) => {
    return slideData.map((q) => {
      switch (q.type) {
        case "svg":
          return <SvgHl data={q.svg.soubory} />;
        case "uzivatelskyText":
          return <TextAreaHl data={q} setWordCountArray={setWordCountArray} />;
        default:
          return <div>Něco se pokazilo</div>;
      }
    });
  };

  return (
    <div className={"exercise-slide"}>
      <div className={"exercise-slide-render"}>{renderSlide(slideData)}</div>
      <SlideEvaluation
        evaluationSlideQuestions={evaluationSlideQuestions}
        evaluationValuesSlide={evaluationValuesSlide}
        onChangeInput={onChangeInput}
        setFieldValue={setFieldValue}
        wordCountArray={wordCountArray}
      />
    </div>
  );
}

export default ExerciseSlide;
