import {  useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./RatingPage.css";

function RatingPage() {
    const [personNumber, setPersonNumber] = useState(2);
    const navigate = useNavigate();

    return (
        <div className="rating-page">
            <div className={"id-select"}>
                <input type="number" id="openEvaluation" name="openEvaluation" value={personNumber} onChange={(e) => {
                    setPersonNumber(e.target.value)
                }}/>
                <button disabled={!personNumber} onClick={() =>{navigate(`/rating/evaluation/${personNumber}`)}}>Start rating</button>
            </div>
            <Link to="/rating/result"><button className={"bt-navigation"}>Result</button></Link>
            <Link to="/rating/result-all/22-2-22"><button className={"bt-navigation"}>Výsledky 22.2.22</button></Link>
        </div>
    );
}

export default RatingPage;
