import React, { Component } from "react";
import history from "../history";

const ApplicationContext = React.createContext();

export class ApplicationStateProvider extends Component {
  constructor(props) {
    super();

    let user = null;
    try {
      user =
        (props && props.value && props.value.user) ||
        JSON.parse(localStorage.getItem("user"));
    } catch (error) {
      console.log("-> error", error);
    }

    if (user) {
      const now = Date.now();
      if (user.time && user.time - now < 0) {
        user = null;
      }
    }

    this.state = {
      authenticationError: null,
      login: this.login,
      user,
    };
  }

  login = async (userName, password) => {
    let response = await fetch("/api/auth/signin", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username: userName, password }),
    });

    response = await response.json();

    if (response.accessToken) {
      response.time = Date.now() + 7 * 24 * 60 * 60; //24h
      localStorage.setItem("user", JSON.stringify(response));
      history.push("/");
      window.location.reload(true);
    }
  };

  onAuthSuccess = (data, redirect, notify) => {
    const user = data;
    user.time = Date.now() + user.expiration;

    this.setState({
      user,
      authenticationRequired: false,
      authenticationError: null,
    });

    sessionStorage.setItem("user", JSON.stringify(data));
  };

  onAuthFailure = (error, notify) => {
    if (!error.response) {
      error.response = {};
    }

    this.setState({
      authenticationError: {
        status: error.response.status,
        statusText: error.response.statusText,
        message: error.response.data,
      },
      authenticated: false,
      authenticationRequired: true,
    });

    if (error.response && error.response.status === 401) {
      notify({
        type: "danger",
        text: "Nepovedlo se prihlaseni",
        component: Notification,
      });
      return;
    }

    notify(error);
  };

  logout = async () => {
    // await new DataService().logout();
    sessionStorage.removeItem("user");
    localStorage.removeItem("user");
    this.setState({
      authenticated: false,
      authenticationRequired: true,
      user: null,
    });
  };

  render() {
    const { children } = this.props;
    const { user, authenticationRequired, authenticationError } = this.state;

    return (
      <ApplicationContext.Provider
        value={{
          login: this.login,
          onAuthSuccess: this.onAuthSuccess,
          onAuthFailure: this.onAuthFailure,
          user,
          authenticated: user !== null,
          authenticationRequired,
          authenticationError,
          logout: this.logout,
        }}
      >
        {children}
      </ApplicationContext.Provider>
    );
  }
}

export const ApplicationStateConsumer = ApplicationContext.Consumer;