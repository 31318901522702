import { Buffer } from "buffer";

import "../coding/modules/scss/styles.scss";
import "../coding/modules/scss/svgHl.scss";

function SvgHistoryLab({ data }) {
  function createMarkup(data) {
    let base64ToString = Buffer.from(data.answer, "base64").toString();
    // const regexWidth = /xlink:href="([\s\S]*?)"/gm;
    // const linkPicture = base64ToString.match(regexWidth)[0];
    // base64ToString = base64ToString.replaceAll(
    //   `${linkPicture}`,
    //   `xlink:href="${process.env.REACT_APP_ASSET_URL}cviceni-aktualni/assets/img/${data.soubor}"`
    // );
    // base64ToString = base64ToString.replaceAll(
    //   "/co-kluci-provedli/pic-00-1280w.jpg",
    //   "https://app.testlab4.felk.cvut.cz/cviceni-verze/v7-1/assets/img/co-kluci-provedli/pic-00-1280w.jpg"
    // );
    //
    // base64ToString = base64ToString.replaceAll(
    //   "/co-kluci-provedli/pic-00-1024w.jpg",
    //   "https://app.testlab4.felk.cvut.cz/cviceni-verze/v7-1/assets/img/co-kluci-provedli/pic-00-1024w.jpg"
    // );
    //
    // base64ToString = base64ToString.replaceAll(
    //   "<textarea",
    //   "<textarea readonly"
    // );
    // // let clean = DOMPurify.sanitize(base64ToString);
    return { __html: `${base64ToString}` };
  }

  return (
    <>
      {data &&
        data.map((svg, index) => {
          return (
            <div
              className={"svg-hl"}
              key={`svg-pic-${index}`}
              dangerouslySetInnerHTML={createMarkup(svg)}
            />
          );
        })}
    </>
  );
}

export default SvgHistoryLab;