import { useState } from "react";
import "./LoginPage.css";
import { login } from "../../service/auth.service";

// async function loginUser(credentials) {
//   return fetch("/api/auth/signin", {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify(credentials),
//   }).then((response) => {
//     if (response.data.accessToken) {
//       localStorage.setItem("user", JSON.stringify(response.data));
//     }
//   });
// }

function LoginPage({ login, onAuthSuccess, onAuthFailure, redirect }) {
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    login(username, password);
    // const response = await login(username, password);
    // if (response.accessToken) setToken(response.accessToken);
    // else {
    //   setErrorMessage(response.message);
    //   setTimeout(() => setErrorMessage(""), 3000);
    // }
  };

  return (
    <div className="login-wrapper">
      <h1>Přihlašte se</h1>
      <form onSubmit={handleSubmit}>
        <div className={"input-login"}>
          <label>
            <div>Přihlašovací jméno</div>
            <input type="text" onChange={(e) => setUserName(e.target.value)} />
          </label>
        </div>
        <div className={"input-login"}>
          <label>
            <div>Heslo</div>
            <input
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </label>
        </div>
        <div className={"button-wrapper"}>
          <button type="submit">Přihlásit se</button>
        </div>
        {errorMessage && <div>Error: {errorMessage}</div>}
      </form>
    </div>
  );
}

export default LoginPage;
